import React from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";

import Loading from "../../components/Loading";
import Content from "./Content";

import { windowObj, repoWindows } from "../../store";
import { useReposApi } from "../../githubApi";

import "./styles.scss";

export default function Repo({ name }) {
  
  return (
    <div className="repoWindow">
      "helo"
    </div>
  );
}

Repo.propTypes = {
  name: PropTypes.string.isRequired,
};
