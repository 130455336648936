import React, { useEffect, useState } from "react";
import { Button, Tabs, Tab } from "react95";

const INFLUENCER_WALLETS = [
  "JCwihSTUbtcznaPWPeKBPzsQwjCLVQ1xGMb39he1NSJZ", // dex
  "5DhGkDAtN1DYUtwbZb29sQJxhbv3XmxMHavvuQLxRLa7", // nitro
  "5DhGkDAtN1DYUtwbZb29sQJxhbv3XmxMHavvuQLxRLa7", // goat
  "8RfwWnCb1jhSdvxTxF4JY3UyvKZiPrGdxyE2rYESJcNS", // royal
  "AZBnQywQ65DxUH7dtieoWzYaf1HdeXShu6WMqBCkEvjp", // vlog
  "6wiMgPyBmhfVKTWdUvpo945DB5Fi4xBh4Gtj8RW2CvwL", // infinity
  "BTUrnSKEgn9dqX1Qd3gdoxJVBPXm2eRaCHgPfK1R5f9Y", // volt
  "5CkpcRm7hXzL6Wp7yJKDc7xQinQuye3U1uHZ5uEZ9ETL", // legends
  "EizWQg8s5N9SrPjfH4o7G55AcwD5p2k6gjaahJRD21nn", // bullrun
  "BWvxWvTasf6uLo1QZB7bTWDq8b75b4qB5sFeK5i8R7qM", // empire
  "2fqznUfyHG4otJSjDg73C1ajhZyCMJbsXikVh5iGNt2J", // league
  "2fkgeCbNFd599ZeMTs4sKD7iCjRvfMWrgKVoMvGkEzcs", // galaxy
  "4RTwvsZ4RTvbjBCwHt2XjQhGthxMSsWkAR5cuTqchZyi", // chino
  "7FmrBHsS7PpDYzwF1JA3h8UmsQGb2HRCRk1yevYk7HMg", // show
  "A3mxazMwmGaaJZL5ePzMFejiveP1Cb4doBL798RtpKdr", // dealer
  "DM2U3XU189dvqxEwkYEfusfvC4zV1CpCYHD7ZgZMVYJ", // 100k
];

export default function ReferralLeaderboard() {
  const [regularReferrers, setRegularReferrers] = useState([]);
  const [influencerReferrers, setInfluencerReferrers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const obfuscateAddress = (address) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const isProd = process.env.NODE_ENV === "production";

  const backendEndpoint = isProd
    ? process.env.REACT_APP_PROD_BACKEND_ENDPOINT
    : process.env.REACT_APP_DEV_BACKEND_ENDPOINT;

  const fetchReferrals = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        backendEndpoint + "users/get-referral-leaderboard"
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const referrers = await response.json();

      const processedReferrers = referrers.map((referrer) => ({
        walletAddress: obfuscateAddress(referrer.walletAddress),
        referralCode: referrer.referralCode,
        referralCount: referrer.referralCount,
        originalAddress: referrer.walletAddress,
      }));

      const influencers = processedReferrers
        .filter((referrer) =>
          INFLUENCER_WALLETS.includes(referrer.originalAddress)
        )
        .sort((a, b) => b.referralCount - a.referralCount)
        .slice(0, 20);

      const regularUsers = processedReferrers
        .filter(
          (referrer) => !INFLUENCER_WALLETS.includes(referrer.originalAddress)
        )
        .sort((a, b) => b.referralCount - a.referralCount)
        .slice(0, 20);

      setInfluencerReferrers(influencers);
      setRegularReferrers(regularUsers);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  const handleRefresh = () => {
    fetchReferrals();
  };

  const userId = localStorage.getItem("user_id");
  const isInfluencer = INFLUENCER_WALLETS.includes(userId);

  const renderLeaderboard = (referrers, title) => (
    <>
      <h3>{title}</h3>
      {referrers.length === 0 ? (
        <p>No users have made referrals yet.</p>
      ) : (
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Referrals</th>
              <th>Referral Code</th>
            </tr>
          </thead>
          <tbody>
            {referrers.map((referrer, index) => (
              <tr key={index} className="leaderboard-item">
                <td>
                  {index === 0
                    ? "🏆"
                    : index === 1
                    ? "🥈"
                    : index === 2
                    ? "🥉"
                    : `${index + 1}.`}
                </td>
                <td>{referrer.referralCount}</td>
                <td>
                  {referrer.referralCode}
                  <div className="wallet-address">
                    {referrer.walletAddress}
                    {referrer.originalAddress === userId && (
                      <strong> (you)</strong>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );

  return (
    <section className="leaderboard-section">
      <div className="leaderboard-header">
        <h2>Referral Leaderboard</h2>
        <Button onClick={handleRefresh} disabled={loading}>
          {loading ? "Refreshing..." : "Refresh"}
        </Button>
      </div>
      {isInfluencer && (
        <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
          <Tab value={0}>Farmers</Tab>
          <Tab value={1}>Influencers</Tab>
        </Tabs>
      )}
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && (
        <>
          {isInfluencer
            ? activeTab === 0
              ? renderLeaderboard(regularReferrers, "Farmers")
              : renderLeaderboard(
                  influencerReferrers,
                  "Influencers"
                )
            : renderLeaderboard(regularReferrers, "Farmers")}
        </>
      )}
    </section>
  );
}
