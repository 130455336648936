import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, TabBody } from "react95";
import { useRecoilValue } from "recoil";

import AnchorButton from "../../components/AnchorButton";
import FileTree from "../../components/FileTree";
import FilePreview from "./FilePreview";
import Issues from "./Issues";
import Readme from "./Readme";
import PullRequests from "./PullRequests";

import { repoFiles } from "../../store";
import { useReposApi } from "../../githubApi";
import capitalize from "../../utilities/capitalize";
import formatBigNumber from "../../utilities/formatBigNumber";

export default function Content({ content, onTreeClick }) {
  return (
    <div className="content">
      <Tabs>
        <Tab>
          <span>Files</span>
          <TabBody>
            <FileTree content={content} onTreeClick={onTreeClick} />
          </TabBody>
        </Tab>
        <Tab>
          <span>Readme</span>
          <TabBody>
            <Readme content={content} />
          </TabBody>
        </Tab>
        <Tab>
          <span>Issues</span>
          <TabBody>
            <Issues content={content} />
          </TabBody>
        </Tab>
        <Tab>
          <span>Pull Requests</span>
          <TabBody>
            <PullRequests content={content} />
          </TabBody>
        </Tab>
      </Tabs>
    </div>
  );
}

FileTree.propTypes = {
  content: PropTypes.object,
  onTreeClick: PropTypes.func,
};

FileTree.defaultTypes = {
  content: undefined,
  onTreeClick: undefined,
};
