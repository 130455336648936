import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

export default function DesktopButton({
  name,
  label,
  icon,
  active,
  onDoubleClick,
}) {
  const [touchStartTime, setTouchStartTime] = useState(null);
  const touchTimeoutRef = useRef(null);

  const handleTouchStart = (e) => {
    e.preventDefault();
    setTouchStartTime(Date.now());

    const touchTimeout = setTimeout(() => {
      setTouchStartTime(null);
    }, 500); // Adjust the timeout duration as needed

    touchTimeoutRef.current = touchTimeout;
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    clearTimeout(touchTimeoutRef.current);

    if (touchStartTime !== null) {
      const elapsedTime = Date.now() - touchStartTime;
      if (elapsedTime < 500) {
        onDoubleClick(e);
      }
    }

    setTouchStartTime(null);
  };

  return (
    <div key={name} className="desktopButton">
      <button
        data-name={name}
        className={`desktopButton__button${name === active ? " -focused" : ""}`}
        onDoubleClick={onDoubleClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <img
          src={icon}
          className="desktopButton__image"
          alt="icon"
          width="50"
        />
        <p className="desktopButton__name">{label}</p>
      </button>
    </div>
  );
}

DesktopButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
