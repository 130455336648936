import React from "react";
import { Button, Anchor } from "react95";

import useNewWindow from "../../hooks/useNewWindow";

import "./styles.scss";

export default function About() {
  const open = useNewWindow();

  const handleClick = () => {
    open("github95", "edwardpayton");
  };

  return (
    <section className="aboutWindow">
      <div className="flex justify-between aboutWindow__header">
        <div className="flex flex-auto items-center">
          <Button variant="menu" size="sm">
            File
          </Button>
          <Button variant="menu" size="sm">
            Edit
          </Button>
          <Button variant="menu" size="sm">
            View
          </Button>
        </div>
      </div>
      <div className="scrollable -yOnly aboutWindow__body">
        <div className="aboutWindow__bodyInner">
          <h1 className="mb2">About WinFarm95</h1>
          <p className="mb4">
            Welcome to WinFarm95 - the ultimate farming experince for the brave
            and bold. Rewind to a time where the tech dotcom bull run started &
            make insane profits!
          </p>

          <h3 className="mb2">How to get started:</h3>

          <p className="mb2">
            1) Navigate to the WinFarm.exe client. This will open the main
            farming portal
          </p>
          <p className="mb2">
            2) Connect your Solana wallet to the WinFarm95 client.
          </p>

          <p className="mb2">
            3) Deposit The amount of SOL tokens you want to stake.
          </p>

          <p className="mb2">
            4) Watch your claim amount rise & rise. You can claim the amount or
            restake to increase your earning potential
          </p>

          <p className="mb2">
            5) Play the desktop games and get the highest score to increase the
            WINCOIN allocation you are allotted
          </p>

          <p className="mb2">
            6) Share your WinFarm95 referral code to boost your claims!
          </p>

          <p className="mb2">
            Finally, Happy Birthday to Windows 95, first announced on 24th
            August 1995 - 29 years young! Long live Windows 95!
          </p>
          <img
            src={require("../../assets/windows95-announcement.gif").default}
            alt=""
          />

          <p className="mt2">All the best, Edward Payton :)</p>
        </div>
      </div>
    </section>
  );
}
