/* eslint-disable default-case */
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import {
  PublicKey,
  SystemProgram,
  TransactionMessage,
  VersionedTransaction,
} from "@solana/web3.js";
import { BigNumber } from "bignumber.js";
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  TextField,
  Button,
  Tabs,
  Tab,
  TabBody,
  List,
  ListItem,
  Fieldset,
  Select,
  NumberField,
} from "react95";
import { Buffer } from "buffer";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { calculateWinningScaled } from "./utils.js";
import { repoSearchStatus } from "../../store";
import DepositSound from "../../components/DepositSound/index.js";
import WinningSound from "../../components/WinningSound/index.js";
import { useParams, useLocation } from "react-router-dom";
import ErrorPopup from "../../components/ErrorPopup/index.js";
import ErrorPopup2 from "../../components/ErrorPopup/index.js";
import ErrorPopup3 from "../../components/ErrorPopup/index.js";
import ErrorPopup4 from "../../components/ErrorPopup/index.js";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import CompoundSound from "../../components/CompoundSound/index.js";

window.Buffer = Buffer;

export default function Searchbar() {
  const { gettingSearch } = useRecoilValue(repoSearchStatus);
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction, wallet } = useWallet();

  // State declarations
  const [activeTab, setActiveTab] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [userDeposits, setUserDeposits] = useState(0);
  const [claimRewards, setClaimRewards] = useState(0);
  const [isClaimBtnDisabled, setIsClaimBtnDisabled] = useState(false);
  const [isCompoundBtnDisabled, setIsCompoundBtnDisabled] = useState(false);
  const [userReferralCode, setUserReferralCode] = useState("");
  const [referralCount, setReferralCount] = useState(0);
  const [shouldFlash, setShouldFlash] = useState(false);
  const [isCompoundSound, setIsCompoundSound] = useState(false);
  const [isClaimSound, setIsClaimSound] = useState(false);
  const [isDepositSound, setIsDepositSound] = useState(false);
  const [solBalance, setSolBalance] = useState(null);
  const [isInsufficientBalanceError, setIsInsufficientBalanceError] =
    useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isProd = process.env.NODE_ENV === "production";

  const backendEndpoint = isProd
    ? process.env.REACT_APP_PROD_BACKEND_ENDPOINT
    : process.env.REACT_APP_DEV_BACKEND_ENDPOINT;

  const devTreasuryWallet = process.env.REACT_APP_DEV_TREASURY_WALLET;
  const devPoolWallet = process.env.REACT_APP_DEV_POOL_WALLET;

  const prodTreasuryWallet = process.env.REACT_APP_PROD_TREASURY_WALLET;
  const prodPoolWallet = process.env.REACT_APP_PROD_POOL_WALLET;

  const code = searchParams?.get("code"); // This is how you would get the "code" query parameter
  //console.log("code", code);

  const handleFirework = (isEmoji) => {
    if (isEmoji) {
      if (window?.confetti) {
        window.confetti({
          spread: 360,
          ticks: 100,
          gravity: 0,
          decay: 0.94,
          startVelocity: 30,
          particleCount: 20,
          scalar: 2,
          shapes: ["emoji"],
          shapeOptions: {
            emoji: {
              value: ["💰", "💸", "🤑", "🪙", "💵"],
            },
          },
        });
      }
    }

    if (!isEmoji) {
      if (window?.confetti) {
        window.confetti({
          spread: 360,
          ticks: 200,
          gravity: 1,
          decay: 0.94,
          startVelocity: 30,
          particleCount: 100,
          scalar: 3,
          shapes: ["image"],
          shapeOptions: {
            image: [
              {
                src: "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
                width: 32,
                height: 32,
              },
            ],
          },
        });
      }
    }
  };

  const handleDepositValChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;

    // Check if value matches allowed pattern (only positive numbers with or without a decimal point)
    if (value === "" || regex.test(value)) {
      // Convert to number if it's a complete number or keep as string if in progress (like ".")
      const numberOrString = value.includes(".")
        ? value
        : value === ""
        ? ""
        : parseFloat(value);
      setDepositAmount(numberOrString);
    }
    // If value does not match the pattern, don't update the state (ignore the input)
  };

  const handleTabChange = (_, tab) => {
    setActiveTab(tab);
  };

  const handleDeposit = useCallback(async () => {
    // Check if deposit amount is greater than or equal to the current balance
    if (solBalance !== null && depositAmount >= solBalance) {
      setIsInsufficientBalanceError(true);
      return; // Exit the function early
    }

    // Wallet addresses
    const DEV_TREASURY_WALLET = "62rK6PT69kMbNHWtb4MYcKwacAn9sxDTh1RKdz7dPfT5";
    const DEV_POOL_WALLET = "4exh4yW3dSMcGq1GmHL8y3hNjT11pvA1wRwzqDAFkaAt";
    const PROD_POOL_WALLET = "AQHXA6myrHYGTvmeLP8MKsbCJZvxCuLVNxsWKEsswDwz";
    const PROD_TREASURY_WALLET = "8GjXxpQXaoSR1fCeLyhdCCSPBTvRX4Vf7rckaM9L2yiL";

    const MAX_RETRIES = 3;
    let retries = 0;

    const solToLamports = (sol) =>
      new BigNumber(sol)
        .multipliedBy(LAMPORTS_PER_SOL)
        .integerValue(BigNumber.ROUND_FLOOR);

    const getNewLatestBlockhash = async (retries = 3) => {
      while (retries > 0) {
        try {
          const { blockhash, lastValidBlockHeight } =
            await connection.getLatestBlockhash();
          return { blockhash, lastValidBlockHeight };
        } catch (error) {
          console.error("Error fetching blockhash:", error);
          retries--;
          if (retries === 0) {
            throw new Error("Failed to fetch blockhash");
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    };

    // Confirmation function
    const confirmTransaction = async (signature, commitment = "processed") => {
      const TIMEOUT = 60000; // 1 minute
      const startTime = Date.now();

      while (Date.now() - startTime < TIMEOUT) {
        const status = await connection.getSignatureStatus(signature);

        if (status && status.value) {
          if (status.value.err) {
            throw new Error(
              `Transaction failed: ${JSON.stringify(status.value.err)}`
            );
          } else if (status.value.confirmationStatus === commitment) {
            return status.value;
          }
        }

        // Wait for a short time before checking again
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      throw new Error(
        `Transaction confirmation timeout after ${TIMEOUT / 1000} seconds`
      );
    };

    // Calculate the amounts to distribute
    const totalLamports = solToLamports(depositAmount);
    const devAmount = totalLamports.multipliedBy(0.05); // 5% to Dev
    const poolAmount = totalLamports.minus(devAmount); // 95% to the pool

    while (retries < MAX_RETRIES) {
      try {
        // if (!wallet.connected || !wallet.publicKey || !wallet.signTransaction) {
        //   throw new Error("Wallet is not connected or not ready to sign");
        // }
        console.log(wallet);

        console.log("Using RPC endpoint:", connection.rpcEndpoint);

        // Log the current balance
        const balance = await connection.getBalance(publicKey);
        console.log(`Current balance: ${balance / LAMPORTS_PER_SOL} SOL`);
        console.log(`Deposit amount: ${depositAmount} SOL`);
        console.log(
          `Dev amount: ${devAmount.dividedBy(LAMPORTS_PER_SOL).toString()} SOL`
        );
        console.log(
          `Pool amount: ${poolAmount
            .dividedBy(LAMPORTS_PER_SOL)
            .toString()} SOL`
        );

        // Fetch a blockhash with a longer lifetime
        const { blockhash, lastValidBlockHeight } =
          await getNewLatestBlockhash();

        const treasuryWallet = new PublicKey(
          isProd ? PROD_TREASURY_WALLET : DEV_TREASURY_WALLET
        );
        const poolWallet = new PublicKey(
          isProd ? PROD_POOL_WALLET : DEV_POOL_WALLET
        );

        console.log("Accounts involved:");
        console.log("0 - Sender (your wallet):", publicKey.toBase58());
        console.log("1 - Treasury wallet:", treasuryWallet.toBase58());
        console.log("2 - Pool wallet:", poolWallet.toBase58());

        // Create instructions
        const transferToTreasuryInstruction = SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: treasuryWallet,
          lamports: devAmount.toNumber(),
        });

        const transferToPoolInstruction = SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: poolWallet,
          lamports: totalLamports.toNumber(),
        });

        // Create a TransactionMessage
        const message = new TransactionMessage({
          payerKey: publicKey,
          recentBlockhash: blockhash,
          instructions: [
            // transferToTreasuryInstruction,
            transferToPoolInstruction,
          ],
        }).compileToV0Message();

        // Create a VersionedTransaction
        const transaction = new VersionedTransaction(message);

        console.log("Transaction details:");
        console.log("From:", publicKey.toBase58());
        console.log("To (Treasury):", treasuryWallet.toBase58());
        console.log("To (Pool):", poolWallet.toBase58());
        console.log("Amount (Treasury):", devAmount.toNumber(), "lamports");
        console.log("Amount (Pool):", poolAmount.toNumber(), "lamports");
        console.log("Recent blockhash:", blockhash);

        // Simulate the transaction
        console.log("Simulating transaction...");
        const { value: simulationResult, context } =
          await connection.simulateTransaction(transaction);

        if (simulationResult.err) {
          console.error("Transaction simulation failed:", simulationResult.err);
          throw new Error(
            `Transaction simulation failed: ${JSON.stringify(
              simulationResult.err
            )}`
          );
        }

        console.log("Simulation successful. Logs:", simulationResult.logs);

        // Sign the transaction
        console.log("Signing transaction...");
        const signedTx = await signTransaction(transaction);
        console.log("Signed transaction:", signedTx);

        if (!signedTx) {
          throw new Error("Transaction was not signed properly");
        }

        // Send the signed transaction
        console.log("Sending transaction...");
        const txSig = await sendTransaction(signedTx, connection, {
          skipPreflight: true,
          preflightCommitment: "confirmed",
        });

        console.log("Transaction sent. Signature:", txSig);

        // Wait for confirmation
        console.log("Waiting for confirmation...");
        try {
          const confirmationStatus = await confirmTransaction(
            txSig,
            "confirmed"
          );
          console.log("Transaction confirmed:", confirmationStatus);

          // Proceed with your backend calls and state updates
          fetch(`${backendEndpoint}transactions/deposit-sol`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              walletAddress: publicKey.toBase58(),
              txId: txSig,
              depositAmount: depositAmount,
              referredBy: code,
            }),
          })
            .then((response) => response.json())
            .then((data) =>
              console.log("Transaction ID sent to backend:", data)
            )
            .catch((error) =>
              console.error("Error sending transaction ID to backend:", error)
            );

          setIsDepositSound(true);

          fetch(`${backendEndpoint}transactions/view-user-deposits`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({ walletAddress: publicKey.toBase58() }),
          })
            .then((response) => response.json())
            .then((data) => setUserDeposits(data.data))
            .catch((error) =>
              console.error("Error fetching user deposits:", error)
            );

          break; // Exit the retry loop
        } catch (confirmError) {
          console.error("Confirmation error:", confirmError);
          throw confirmError; // Re-throw to trigger retry
        }
      } catch (error) {
        console.error(`Attempt ${retries + 1} failed:`, error);
        retries++;
        if (retries >= MAX_RETRIES) {
          console.error("Max retries reached. Transaction failed.");
          // Handle the failure (e.g., show an error message to the user)
        } else {
          // Wait for a short time before retrying
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }
  }, [
    depositAmount,
    connection,
    publicKey,
    wallet,
    isProd,
    code,
    backendEndpoint,
    setIsDepositSound,
    setUserDeposits,
    solBalance,
  ]);

  function CustomConnectButton() {
    const [walletModalConfig, setWalletModalConfig] = useState(null);
    const [walletConnected, setWalletConnected] = useState(false);
    const { buttonState, onConnect, onDisconnect, onSelectWallet } =
      useWalletMultiButton({
        onSelectWallet: setWalletModalConfig,
        // solflare is the only wallet adapter we have for now
        wallets: [
          {
            name: "Solflare",
            adapter: new SolflareWalletAdapter(),
          },
        ],
      });

    const fetchWallet = useCallback(async () => {
      try {
        // TODO: CHANGE URL TO PROCESS ENV
        const res = await fetch(`${backendEndpoint}auth/connect-wallet`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            walletAddress: publicKey.toBase58(),
          }),
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        if (publicKey) {
          localStorage.setItem("user_id", publicKey.toBase58());
          console.log("localStorage", localStorage.getItem("user_id"));
        }

        return res.json();
      } catch (error) {
        console.error("Error fetching wallet:", error);
        return null; // Or handle the error as appropriate for your app
      }
    }, [publicKey]); // Assuming publicKey could change and should trigger a re-memoization
    // Add any dependencies that on change should re-create this method

    // Handle label based on button state
    let label;
    switch (buttonState) {
      case "connected":
        label = "Disconnect";
        break;
      case "connecting":
        label = "Connecting";
        break;
      case "disconnecting":
        label = "Disconnecting";
        break;
      case "has-wallet":
        console.log("u hav wallet");
        label = "Connect Wallet";
        break;
      case "no-wallet":
        label = "Select Wallet";
        break;
      default:
        label = "Unknown";
    }

    const discardUserCookies = async () => {
      // Remove user cookies via endpoint disconnect-wallet
      try {
        // TODO: CHANGE URL TO PROCESS ENV
        const res = await fetch(`${backendEndpoint}auth/disconnect-wallet`, {
          method: "POST",
          credentials: "include",
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        // remove user_id from local storage
        localStorage.removeItem("user_id");

        return res.json();
      } catch (error) {
        console.error("Error disconnecting wallet:", error);
        return null; // Or handle the error as appropriate for your app
      }
    };

    // Handle click events
    const handleClick = useCallback(() => {
      switch (buttonState) {
        case "connected":
          onDisconnect();
          // use iife to handle async operation
          (async () => {
            try {
              await discardUserCookies();
            } catch (error) {
              console.error("Error disconnecting wallet:", error);
            }
          })();

          break;
        case "connecting":
        case "disconnecting":
          // No action required
          break;
        case "has-wallet":
          //console.log("has-wallet");

          // Using an Immediately Invoked Function Expression (IIFE) for async operation
          (async () => {
            try {
              await onConnect();
              //console.log("onConnect");
              // setWalletConnected(true); // If you have a state to manage connection, set it here
              // Any additional logic following a successful connection can go here
            } catch (error) {
              console.error("Error connecting wallet:", error);
              // Handle any errors that occur during connection
            }
          })();
          break;
        case "no-wallet":
          onSelectWallet();
          break;
        default:
          // Handle any other states or default case
          break;
      }
    }, [buttonState, onDisconnect, onConnect, onSelectWallet]);

    // Effect to perform the API call after successfully connecting the wallet

    useEffect(() => {
      if (buttonState === "connected") {
        // Perform the API call
        const fetchAndHandleWallet = async () => {
          const res = await fetchWallet();
          // Handle the response from fetchWallet
          // Reset the flag after handling
          setWalletConnected(false);
        };
        fetchAndHandleWallet();
      }
    }, [walletConnected, label]); // This effect depends on the walletConnected state

    useEffect(() => {
      if (buttonState === "connected" && publicKey) {
        fetchBalance();
      }
    }, [buttonState, publicKey]);

    return (
      <div className="custom-connect-button-container">
        <Button
          disabled={
            buttonState === "connecting" || buttonState === "disconnecting"
          }
          onClick={handleClick}
          className="custom-connect-button"
          form="repoSearchForm"
        >
          {label}
        </Button>
        {console.log({ walletModalConfig })}
        {walletModalConfig ? (
          <List className="wallet-list">
            {walletModalConfig.wallets
              .filter((wallet) => wallet.adapter.name === "Solflare")
              .map((wallet) => (
                <ListItem
                  size="sm"
                  primary
                  key={wallet.adapter.name}
                  onClick={() => {
                    walletModalConfig.onSelectWallet(wallet.adapter.name);
                    setWalletModalConfig(null);
                  }}
                >
                  {wallet.adapter.name}
                </ListItem>
              ))}
          </List>
        ) : null}
      </div>
    );
  }

  const lastFetchedPublicKey = useRef(null);

  const fetchBalance = useCallback(async () => {
    if (publicKey && publicKey.toString() !== lastFetchedPublicKey.current) {
      try {
        const balance = await connection.getBalance(publicKey);
        setSolBalance(balance / LAMPORTS_PER_SOL);
        lastFetchedPublicKey.current = publicKey.toString();
      } catch (error) {
        console.error("Error fetching balance:", error);
        setSolBalance(null);
        lastFetchedPublicKey.current = null;
      }
    }
  }, [publicKey, connection]);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  useEffect(() => {
    const triggerFlash = () => {
      setShouldFlash(true);
      setTimeout(() => setShouldFlash(false), 1000); // Match the duration of your animation
    };

    const fetchUserData = async () => {
      try {
        // TODO: CHANGE URL TO PROCESS ENV
        console.log(backendEndpoint);
        const response = await fetch(
          `${backendEndpoint}transactions/view-user-details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ walletAddress: publicKey.toBase58() }),
          }
        );

        console.log({ response });

        // Check for unauthorized access, which might mean the token has expired
        if (response.status === 401) {
          // Attempt to refresh the token
          //
          // TODO: CHANGE URL TO PROCESS ENV
          const refreshRes = await fetch(`${backendEndpoint}auth/refresh`, {
            method: "POST",
            credentials: "include",
          });

          // If the refresh was successful, retry the original request
          if (refreshRes.ok) {
            // TODO: CHANGE URL TO PROCESS ENV
            const retryResponse = await fetch(
              `${backendEndpoint}transactions/view-user-details`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ walletAddress: publicKey.toBase58() }),
              }
            );

            // If the retry fails again, handle it as needed
            if (!retryResponse.ok) {
              throw new Error(
                `HTTP error after refresh! Status: ${retryResponse.status}`
              );
            }

            const userData = await retryResponse.json();
            setUserDeposits(userData.data.accumulatedDeposits);
            setClaimRewards(userData.data.earnings);
            setUserReferralCode(userData.data.referralCode);
            setReferralCount(userData.data.referralCount);

            triggerFlash();
          } else {
            throw new Error(
              "Refresh token invalid or expired, please log in again."
            );
          }
        } else if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const userData = await response.json();
          setUserDeposits(userData.data.accumulatedDeposits);
          setClaimRewards(userData.data.earnings);
          setUserReferralCode(userData.data.referralCode);
          setReferralCount(userData.data.referralCount);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    // Call fetchUserData initially
    fetchUserData();

    // Set up the interval to refresh data every 5 seconds
    const intervalId = setInterval(fetchUserData, 5000);

    // Clean-up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [publicKey]); // Dependency on publicKey to re-run effect if it changes

  // useEffect(() => {
  const makeClaimRewards = async () => {
    try {
      setIsClaimBtnDisabled(true);
      setIsCompoundBtnDisabled(true);

      // TODO: CHANGE URL TO PROCESS ENV
      const response = await fetch(
        `${backendEndpoint}transactions/make-claim-reward`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Include cookies with the request
          body: JSON.stringify({
            walletAddress: publicKey.toBase58(),
          }),
        }
      );
      const newData = await response.json();
      setClaimRewards(newData);
      setIsClaimBtnDisabled(false);
      setIsCompoundBtnDisabled(false);
      setIsClaimSound(true);
      // setIsClaimSound(false);
      handleFirework(true);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setIsClaimBtnDisabled(false);
      setIsCompoundBtnDisabled(false);
    }
  };

  const makeCompoundRewards = async () => {
    try {
      setIsCompoundBtnDisabled(true);
      setIsClaimBtnDisabled(true);

      // TODO: CHANGE URL TO PROCESS ENV
      const response = await fetch(
        `${backendEndpoint}transactions/make-compound-reward`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Include cookies with the request
          body: JSON.stringify({
            walletAddress: publicKey.toBase58(),
          }),
        }
      );
      await response.json();

      setIsCompoundBtnDisabled(false);
      setIsClaimBtnDisabled(false);
      setIsCompoundSound(true);
      // setIsCompoundSound(false);
      handleFirework();
    } catch (error) {
      console.error("Failed to fetch data:", error);
      // console.error("Failed to fetch data:", error);
      setIsCompoundBtnDisabled(false);
      setIsClaimBtnDisabled(false);
    }
  };

  const handleClaimRewards = async () => {
    makeClaimRewards();
  };

  const handleCompoundRewards = async () => {
    makeCompoundRewards();
  };

  // need a function to only show first few and last few characters of a wallet addresses

  const obfuscateWalletAddress = (walletAddress) => {
    return `${walletAddress.slice(0, 5)}...${walletAddress.slice(-5)}`;
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://cdn.jsdelivr.net/npm/@tsparticles/confetti@3.0.3/tsparticles.confetti.bundle.min.js";

    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // set the wallet address in local storage
    if (publicKey) {
      localStorage.setItem("user_id", publicKey.toBase58());
      console.log("localStorage", localStorage.getItem("user_id"));
    }
  }, [publicKey]);

  return (
    <div className="flex flex-column items-stretch searchBar">
      {console.log("Current solBalance:", solBalance)}
      {isCompoundSound && (
        <ErrorPopup dismissable header={"Success!"}>
          <p className="pb1">Your funds were successfully compounded!</p>
        </ErrorPopup>
      )}
      {isDepositSound && (
        <ErrorPopup2 dismissable header={"Success!"}>
          <p className="pb1">Your funds were successfully deposited!</p>
        </ErrorPopup2>
      )}
      {isClaimSound && (
        <ErrorPopup3 dismissable header={"Success!"}>
          <p className="pb1">Your rewards were successfully claimed!</p>
        </ErrorPopup3>
      )}
      {isInsufficientBalanceError && (
        <ErrorPopup4
          dismissable
          header={"Insufficient Balance"}
          onDismiss={() => setIsInsufficientBalanceError(false)}
        >
          <p className="pb1">
            Make sure you have enough balance to cover the transaction + gas
            fees.
          </p>
        </ErrorPopup4>
      )}
      <div className="dex-scroll-container">
        <CustomConnectButton />
        <Tabs value={activeTab} onChange={handleTabChange} className="tabs">
          <Tab
            value={0}
            onPointerDown={handleTabChange}
            className="tabs__tab searchBar__tab"
          >
            <p>Farming!</p>
          </Tab>
          <Tab
            value={1}
            onPointerDown={handleTabChange}
            className="tabs__tab searchBar__tab"
          >
            <p>Deposits</p>
          </Tab>
          <Tab
            value={2}
            onPointerDown={handleTabChange}
            className="tabs__tab searchBar__tab"
          >
            <p>Referrals</p>
          </Tab>
          <Tab
            value={3}
            onPointerDown={handleTabChange}
            className="tabs__tab searchBar__tab"
          >
            <p>Stats</p>
          </Tab>
        </Tabs>
        <TabBody className="flex-auto searchBar__tabBody tab">
          <form id="repoSearchForm" className="searchBar__form">
            {activeTab === 0 && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <p className="searchBar__label mt2">WinCoin Allocation:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={
                      publicKey
                        ? `${
                            Math.round(
                              calculateWinningScaled(userDeposits).winningTokens
                            ) ?? 0
                          } $WINCOIN (Tier: ${
                            calculateWinningScaled(userDeposits).tier ?? "Poor"
                          })`
                        : "0 $WINCOIN (Tier: Poor)"
                    }
                    className="flex-auto searchBar__input mb2"
                  />

                  <p className="searchBar__label">Rewards:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={
                      !publicKey
                        ? "0 SOL"
                        : `${claimRewards ? claimRewards : "0"} SOL`
                    }
                    className={`flex-auto searchBar__input ${
                      shouldFlash ? "flash-effect" : ""
                    }`}
                  />
                </div>

                <div className="wrapBtnStyles mt2">
                  <Button
                    className="searchBar__button mr1"
                    onClick={handleClaimRewards}
                    form="repoSearchForm"
                    // disabled={isClaimBtnDisabled}
                    disabled
                  >
                    Claim Rewards
                  </Button>

                  <Button
                    className="searchBar__button"
                    onClick={handleCompoundRewards}
                    form="repoSearchForm"
                    // disabled={isCompoundBtnDisabled}
                    disabled
                  >
                    Compound Rewards
                  </Button>
                </div>
              </>
            )}
            {activeTab === 1 && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {publicKey && solBalance !== null ? (
                    <div className="flex login__input mb2">
                      <p>SOL Balance:</p>
                      <TextField
                        value={
                          solBalance !== null
                            ? `${solBalance.toFixed(4)} SOL`
                            : "Fetching balance..."
                        }
                        disabled
                      />
                    </div>
                  ) : (
                    <p>Connect your wallet to view SOL balance</p>
                  )}
                  <p className="searchBar__label mt2">Total Deposited:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={!publicKey ? "0 SOL" : `${userDeposits} SOL`}
                    className="flex-auto searchBar__input mb2"
                  />

                  <p className="searchBar__label">SOL Amount to Deposit:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={depositAmount}
                    placeholder={depositAmount ?? " 0 SOL"}
                    onChange={handleDepositValChange}
                    className="flex-auto searchBar__input"
                  />
                </div>

                <div className="mt2 w100">
                  <Button
                    onClick={handleDeposit}
                    className="searchBar__button mr1"
                    form="repoSearchForm"
                    //disabled={
                    //  solBalance === null || depositAmount >= solBalance
                    //}
                    disabled
                  >
                    Deposit
                  </Button>
                </div>
              </>
            )}
            {activeTab === 2 && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <p className="searchBar__label mt2">Referral URL:</p>
                  <div>
                    <TextField
                      style={{ width: "100%" }}
                      value={`https://winfarm95.com/ref?code=${userReferralCode}`}
                      className="flex-auto searchBar__input  mb2"
                    />
                  </div>

                  <p className="searchBar__label">Referral Count:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={referralCount}
                    className="flex-auto searchBar__input"
                  />
                </div>
              </>
            )}
            {activeTab === 3 && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <p className="searchBar__label mt2">TVL:</p>
                  <div>
                    <TextField
                      style={{ width: "100%" }}
                      value={0}
                      className="flex-auto searchBar__input  mb2"
                    />
                  </div>

                  <p className="searchBar__label">Daily Return:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={"8%"}
                    className="flex-auto searchBar__input"
                  />

                  <p className="searchBar__label">APR:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={"3000%"}
                    className="flex-auto searchBar__input"
                  />

                  <p className="searchBar__label">Dev Fee:</p>
                  <TextField
                    style={{ width: "100%" }}
                    value={"5%"}
                    className="flex-auto searchBar__input"
                  />
                </div>
              </>
            )}
          </form>
        </TabBody>
      </div>
      <div className="trendingWindow__footer">
        <p>
          {publicKey
            ? `Wallet: ${obfuscateWalletAddress(publicKey.toBase58())}`
            : "No Wallet Found"}
        </p>
      </div>
    </div>
  );
}
