const welcome = {
  label: "Welcome",
  header: "Welcome to WinFarm95",
  desktopIcon: "",
  desktopPosition: null,
  visibility: [true, true], // eg [button visible (show button), window visible (show window)]
};

const repos = {
  label: "WinFarm.exe",
  header:
    '<img src="' +
    `${require("../assets/winfarm95.jpg")}` +
    '" /> W - \\WinFarm.exe',
  desktopIcon: `${require(`../assets/winfarm95.jpg`)}`,
  desktopPosition: 0,
  visibility: [false, false],
};

const trending = {
  label: "win_trailer.mov",
  header:
    '<img src="' +
    require("../assets/youtubepix.png").default +
    '" /> Youtube - WinFarm95tv',
  desktopIcon: `${require(`../assets/youtubepix.png`)}`,
  desktopPosition: 7,
  visibility: [false, false],
};

const about = {
  label: "about.txt",
  header: "Notepad - About WinFarm95.txt",
  desktopIcon: `${require(`../assets/wordpad.png`)}`,
  desktopPosition: 4,
  visibility: [false, false],
};

const mostFollowed = {
  label: "flappybird.exe",
  header:
    '<img src="' +
    `${require("../assets/flappybird.png").default}` +
    '" /> Flappy Bird - \\flappybird.exe',
  desktopIcon: `${require(`../assets/flappybird.png`)}`,
  desktopPosition: 6,
  visibility: [false, false],
};

const gitbooks = {
  label: "gitbooks.htm",
  header: "GitBooks - WinFarm95",
  desktopIcon: `${require(`../assets/gitbooks.png`)}`,
  desktopPosition: 8,
  visibility: [false, false],
};

const booklet = {
  label: "booklet.pdf",
  header: "Booklet - WinFarm95",
  desktopIcon: `${require(`../assets/clipart.png`)}`,
  desktopPosition: 9,
  visibility: [false, false],
};

const tutorial = {
  label: "tutorial.mov",
  header:
    '<img src="' +
    require("../assets/wmp95.png").default +
    '" /> Youtube - WinFarm95tv',
  desktopIcon: `${require(`../assets/wmp95.png`)}`,
  desktopPosition: 10,
  visibility: [false, false],
};

const explainer = {
  label: "explainer.mov",
  header:
    '<img src="' +
    require("../assets/wmp95.png").default +
    '" /> Youtube - Explainer',
  desktopIcon: `${require(`../assets/wmp95.png`)}`,
  desktopPosition: 11,
  visibility: [false, false],
};

const scoreboard = {
  label: "scoreboard.exe",
  header:
    '<img src="' +
    require("../assets/scoreboard2.png").default +
    '" /> Scoreboard.exe',
  desktopIcon: `${require(`../assets/scoreboard2.png`)}`,
  desktopPosition: 11,
  visibility: [false, false],
};

const twitter = {
  label: "twitter.html",
  header:
    '<img src="' +
    require("../assets/twitter.png").default +
    '" /> twitter.url',
  desktopIcon: `${require(`../assets/twitter.png`)}`,
  desktopPosition: 12,
  visibility: [false, false],
}

const dex = {
  label: "dex.exe",
  header: "Dex - WinFarm95",
  desktopIcon: `${require(`../assets/DEX_XCHNGE.png`)}`,
  desktopPosition: 12,
  visibility: [false, false],
};

const referralLeaderboard = {
  label: "REFERRAL_LEADERBOARD.exe",
  header:
    '<img src="' +
    require("../assets/scoreboard2.png").default +
    '" /> Referral_Leaderboard.exe',
  desktopIcon: `${require(`../assets/scoreboard2.png`)}`,
  desktopPosition: 13,
  visibility: [false, false],
};

const tetris = {
  label: "tetris.exe",
  header: "Tetris - WinFarm95",
  desktopIcon: `${require(`../assets/tetris.png`)}`,
  desktopPosition: 14,
  visibility: [false, false],
};

const mariokart = {
  label: "mariokart.exe",
  header: "Mario Kart - WinFarm95",
  desktopIcon: `${require(`../assets/mariokart.png`)}`,
  desktopPosition: 15,
  visibility: [false, false],
};

const streetFighter = {
  label: "streetfighter.exe",
  header: "Street Fighter - WinFarm95",
  desktopIcon: `${require(`../assets/sf2.jpg`)}`,
  desktopPosition: 16,
  visibility: [false, false],
};

// const trending

export const WINDOW_OBJ = {
  welcome,
  about,
  repos,
  mostFollowed,
  trending,
  gitbooks,
  booklet,
  tutorial,
  explainer,
  scoreboard,
  dex,
  referralLeaderboard,
  twitter,
  tetris,
  mariokart,
  streetFighter
  // mostFollowed,
  // winFarm,
};

export const DEFAULT_WINDOW = {
  label: ">default<",
  header: "", // if empty, use label
  desktopIcon: false,
  visibility: [false, false],
};

// Used for recoil atom ids
export const USER = "user";
export const REPOS = "repos";

// copying the github api file tree structure for use in the trending window
export const TRENDING_TREE = [
  {
    name: "repositories",
    type: "tree",
    open: true, // my addition to have this tree open by default
    object: {
      entries: [
        {
          name: "daily",
          type: "blob",
        },
        {
          name: "weekly",
          type: "blob",
        },
        {
          name: "monthly",
          type: "blob",
        },
      ],
    },
  },
];
