import React from "react";
import { useRecoilState } from "recoil";

import StartupSound from "../../components/StartupSound";
import Taskbar from "../Taskbar";
import Windows from "./Windows";
import DesktopButton from "./DesktopButton";

import useLocalStorage from "../../hooks/useLocalStorage";
import { WINDOW_OBJ } from "../../constants";
import { windowObj } from "../../store";
import reducer, {
  SET_LOADING,
  SET_TASKBAR,
  SET_ICONS,
  SET_WINDOWS,
} from "./reducer";

import "./styles.scss";

const desktopIcons = (() => {
  console.log({ WINDOW_OBJ });
  const icons = Object.keys(WINDOW_OBJ)
    .filter((name) => (WINDOW_OBJ[name].desktopIcon ? name : null))
    .sort(
      (a, b) => WINDOW_OBJ[a].desktopPosition - WINDOW_OBJ[b].desktopPosition
    );

  return icons;
})();

const splitDesktopIcons = (icons) => {
  const midpoint = Math.ceil(icons.length / 2);
  return [icons.slice(0, midpoint), icons.slice(midpoint)];
};


console.log({ desktopIcons });

export default function Desktop() {
  const [currentWindows, setWindows] = useRecoilState(windowObj);
  // const limit = useRecoilValue(apiLimit);
  const [active, setActive] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [soundStorage, _] = useLocalStorage("github95_noSound");

  const [{ showLoader, showTaskbar, showIcons, showWindows }, dispatch] =
    reducer();

  const handleDesktopClick = ({ target }) => {
    const { name } = target.dataset;
    setActive(name || "");
  };

  const handleButtonDblClick = (name) => (e) => {
    e.stopPropagation();
    const updated = {
      [name]: { ...currentWindows[name], visibility: [true, true] },
    };

    // if name = trending, open a new tab with Youtube
    if (name === "trending") {
      window.open("https://youtu.be/_c6zXQzFdU0?si=fn-LsOgYrI-EaxK_", "_blank");
      return;
    }

    if (name === "gitbooks") {
      window.open("https://winfarm.gitbook.io/winfarm-95", "_blank");
      return;
    }

    if (name === "booklet") {
      window.open("https://drive.google.com/file/d/1kcyk-uThwWMWmXs3Rty3E0KgyZPAvG-R/view?usp=sharing", "_blank");
      return;
    }

    if (name === "tutorial") {
      window.open("https://www.youtube.com/watch?v=YeubrekmufM", "_blank");
      return;
    }

    if (name === "explainer") {
      window.open("https://www.youtube.com/watch?v=bmZ6xuJ6_aU", "_blank");
      return;
    }

    if (name === "twitter") {
      window.open("https://x.com/winfarm95")
    }

    // if ()

    setWindows({ ...currentWindows, ...updated });
  };

  const handleTouchStart = (name) => (e) => {
    console.log("touchstart", name, e);
    e.persist();
    e.preventDefault(); // Add this line

    const handleTouchEnd = () => {
      const now = Date.now();
      const isDoubleClick = now - lastTouchStart < 500; // Adjust the threshold as needed
      if (isDoubleClick) {
        handleButtonDblClick(name)(e);
      }
      lastTouchStart = now;
    };
    const options = { once: true, passive: false }; // Add passive: false

    e.target.addEventListener("touchend", handleTouchEnd, options);
    e.target.addEventListener("touchendX", handleTouchEnd, options); // Add vendor-prefixed event
  };

  let lastTouchStart = 0;

  React.useEffect(() => {
    // @ts-ignore
    dispatch({ type: SET_LOADING });
    window.setTimeout(() => {
      // @ts-ignore
      dispatch({ type: SET_TASKBAR });
    }, 500);
    window.setTimeout(() => {
      // @ts-ignore
      dispatch({ type: SET_ICONS });
    }, 1250);
    window.setTimeout(() => {
      // @ts-ignore
      dispatch({ type: SET_WINDOWS });
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const toggle = showLoader ? "add" : "remove";
    document.body.classList[toggle]("isLoading");
  }, [showLoader]);

  return (
    <>
      {showTaskbar && (
        <>
          <Taskbar />
          {soundStorage !== "Off" && <StartupSound />}
        </>
      )}
      <main>
        <section className="desktop">
          <section
            className="desktop__background grid-container"
            onClick={handleDesktopClick}
          >
            {showIcons &&
              desktopIcons.map((name) => {
                const { label, desktopIcon } = WINDOW_OBJ[name];
                return (
                  <React.Fragment key={name}>
                    {desktopIcon.length && (
                      <DesktopButton
                        name={name}
                        label={label}
                        icon={desktopIcon}
                        active={active}
                        onTouchStart={handleTouchStart(name)}
                        onDoubleClick={handleButtonDblClick(name)}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </section>

          {showWindows && <Windows />}
        </section>
      </main>
    </>
  );
}
