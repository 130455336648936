import React from "react";
import ErrorPopup from "../../components/ErrorPopup";

const Game = () => {
  return (
    <ErrorPopup header="Game Not Ready" dismissable={true}>
      <p>The game is not ready yet. Please check back later.</p>
    </ErrorPopup>
  );
};

export default Game;
