import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { trending } from "../../store";
import "./styles.scss";

export default function Trending() {
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const trends = useRecoilValue(trending);

  const obfuscateAddress = (address) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const isProd = process.env.NODE_ENV === "production";

  const backendEndpoint = isProd
    ? process.env.REACT_APP_PROD_BACKEND_ENDPOINT
    : process.env.REACT_APP_DEV_BACKEND_ENDPOINT;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          backendEndpoint + "users/get-flappybird-scores"
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const users = await response.json();

        const top20Users = users
          .filter((user) => user.flappyBirdScore > 0) // Filter users only with score greater than 0
          .map((user) => ({
            walletAddress: obfuscateAddress(user.walletAddress),
            flappyBirdScore: user.flappyBirdScore,
            originalAddress: user.walletAddress, // Keep original address for comparison
          }))
          .sort((a, b) => b.flappyBirdScore - a.flappyBirdScore)
          .slice(0, 20);

        setTopUsers(top20Users);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const userId = localStorage.getItem("user_id");
  const obfuscatedUserId = userId ? obfuscateAddress(userId) : null;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <section className="leaderboard-section">
      {topUsers.length === 0 ? (
        <p>No users have made the leaderboard.</p>
      ) : (
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {topUsers.map((user, index) => (
              <tr key={index} className="leaderboard-item">
                <td>
                  {index === 0
                    ? "🏆"
                    : index === 1
                    ? "🥈"
                    : index === 2
                    ? "🥉"
                    : `${index + 1}.`}
                </td>
                <td>
                  {user.flappyBirdScore}
                  <div className="wallet-address">
                    {user.walletAddress}
                    {user.originalAddress === userId && <strong> (you)</strong>}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
}
