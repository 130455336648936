import React, { useMemo } from "react";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { styleReset } from "react95";
import original from "react95/dist/themes/original";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Desktop from "./views/Desktop";
import Login from "./views/Login";

import { menubarButtons, focusedElement } from "./store";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";

import "./App.css";

const ResetStyles = createGlobalStyle`
  ${styleReset}
`;

function AppWrapper() {
  const [focused, setfocused] = useRecoilState(focusedElement);
  const currentButtons = useRecoilValue(menubarButtons);
  const [showDesktop, setShowDesktop] = React.useState(false);

  const handleClick = React.useCallback(
    (e) => {
      if (e.target.dataset && e.target.dataset.name === "start-menu") return;
      const closest = e.target.closest("[data-name]");
      if (!closest) return setfocused("");
      const { name } = closest.dataset;
      setfocused(name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focused, setfocused, currentButtons]
  );

  const handleLogin = () => {
    document.addEventListener("click", handleClick);
    document.addEventListener("touchstart", handleClick);
    setShowDesktop(true);
  };

  return <>{showDesktop ? <Desktop /> : <Login onLogin={handleLogin} />}</>;
}

const App = () => {
  // TODO: ADD CONDITIONAL TO SWITCH BETWEEN MAINNET AND DEVNET DEPENDING ON ENV.
  // const devnetEndpoint =
  //   "https://solana-devnet.g.alchemy.com/v2/73hgh-z17RIG1Y0c9IGYTxr_ZaX1wPw4";

  // const prodnetEndpoint =
  //   "https://solana-mainnet.g.alchemy.com/v2/73hgh-z17RIG1Y0c9IGYTxr_ZaX1wPw4";

  const wallets = useMemo(() => [
    new SolflareWalletAdapter()
  ], []);

  return (
    <RecoilRoot>
      <BrowserRouter>
        <ConnectionProvider endpoint={"https://wider-old-voice.solana-mainnet.quiknode.pro/7f1581c1aa93c4593c34a5425fc30122d06d50c7/"}>
          <WalletProvider wallets={wallets} autoConnect>
            <ResetStyles />
            <ThemeProvider theme={original}>
              <Routes>
                <Route path="/ref" element={<AppWrapper />} />
                <Route path="/" element={<AppWrapper />} />
              </Routes>
            </ThemeProvider>
          </WalletProvider>
        </ConnectionProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
