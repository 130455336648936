function linearInterpolation(x, x0, x1, y0, y1) {
  return y0 + (y1 - y0) * ((x - x0) / (x1 - x0));
}

export function calculateWinningScaled(solDeposited) {
  const tiers = [
    { name: "Iron", x0: 0, x1: 1, y0: 0, y1: 153000 },
    { name: "Bronze", x0: 1, x1: 2, y0: 153000, y1: 306000 },
    { name: "Silver", x0: 2, x1: 5, y0: 306000, y1: 612000 },
    { name: "Gold", x0: 5, x1: 10, y0: 612000, y1: 1224000 },
    { name: "Platinum", x0: 10, x1: 20, y0: 1224000, y1: 2448000 },
    { name: "Diamond", x0: 20, x1: 50, y0: 2448000, y1: 4896000 },
    { name: "Unobtainium", x0: 50, x1: 100, y0: 4896000, y1: 9792000 },
    { name: "Infinity", x0: 100, x1: Infinity, y0: 9792000, y1: 17250000 }, // This tier now acts as a base for scaling beyond 100 SOL
  ];

  for (let tier of tiers) {
    if (tier.x0 <= solDeposited && solDeposited < tier.x1) {
      const winningTokens = linearInterpolation(
        solDeposited,
        tier.x0,
        tier.x1,
        tier.y0,
        tier.y1
      );
      return { tier: tier.name, winningTokens: winningTokens };
    }
  }

  // For deposits over 100 SOL, scale slowly
  // Define a much lower exchange rate for scaling beyond 100 SOL
  const baseTokensFor100SOL = 9792000;
  const additionalRatePerSOLAbove100 = 500; // This rate determines how slowly additional tokens are awarded per SOL above 100
  const additionalTokens = (solDeposited - 100) * additionalRatePerSOLAbove100;
  const winningTokens = baseTokensFor100SOL + additionalTokens;

  return { tier: "Beyond Infinity", winningTokens: winningTokens }; // Custom tier for any deposit amount over the last tier's maximum
}
