import React, { useEffect } from "react";
import { Divider } from "react95";
import "./styles.scss";

export default function MostFollowed() {
  useEffect(() => {
    const gameScript = document.createElement("script");

     gameScript.src = "https://winfarm95.com/flappybird.js"


    gameScript.async = true;
    document.body.appendChild(gameScript);

    return () => {
      document.body.removeChild(gameScript);
    };
  }, []);

  return (
    <div
      className="most-followed"
      style={{
        maxWidth: "320px",
        maxHeight: "620px",
      }}
    >
      <h1>High Score = High Multiplier</h1>
      <Divider />
      <div className="most-followed-list">
        <div className="most-followed-repos">
          <div id="gamediv"></div>
        </div>
      </div>
    </div>
  );
}
