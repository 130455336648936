import React from "react";

import { Howl } from "howler";
// console.log(require("../../assets/chord.wav"));

//TODO: ADD THAT ARE YOU A DEV? WORK FOR US THING IN THE CONSOLE LOG IN BROWSER FOR RETRO EFFECT
export default function Chord() {
  const refSound = React.useRef(undefined);

  React.useEffect(() => {
    refSound.current = new Howl({
      // @ts-ignore
      src: [`${require("../../assets/chord.wav")}`],
      volume: 1,
      onend: () => {
        refSound.current = null;
      },
      onloaderror: (id, err) => console.log("Load Error:", err),
      onplayerror: (id, err) => console.log("Play Error:", err),
    });
    refSound.current.once("load", function () {
      console.log("loaded!");
      this.play();
    });
  }, []);

  return null;
}
