import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Tabs,
  Tab,
  TabBody,
  Select,
  NumberField,
  Slider,
  Divider,
  Fieldset,
  List,
  ListItem,
} from "react95";
import { createChart, CrosshairMode } from "lightweight-charts";
import "./style.scss";
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

const DEX = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [selectedFromValue, setSelectedFromValue] = useState("");
  const [selectedToValue, setSelectedToValue] = useState("");
  const [sliderValue, setSliderValue] = useState(50);

  const [amount, setAmount] = useState("");

  const [automationCondition, setAutomationCondition] = useState("");
  const [automationComparison, setAutomationComparison] = useState("");
  const [automationValue, setAutomationValue] = useState(0);
  const [automationAction, setAutomationAction] = useState("");
  const [automationExpiry, setAutomationExpiry] = useState("");

  const [slippage, setSlippage] = useState(2);

  const chartContainerRef = useRef();

  useEffect(() => {
    const chartOptions = {
      width: 400,
      height: 300,
      layout: {
        backgroundColor: "#2B2B2B",
        textColor: "#D9D9D9",
      },
      grid: {
        vertLines: {
          color: "#404040",
        },
        horzLines: {
          color: "#404040",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: "#cccccc",
      },
      timeScale: {
        borderColor: "#cccccc",
        timeVisible: true,
        secondsVisible: false,
      },
    };

    const chart = createChart(chartContainerRef.current, chartOptions);
    const candleSeries = chart.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });

    const data = [
      {
        time: "2018-10-19",
        open: 180.34,
        high: 180.99,
        low: 178.57,
        close: 179.85,
      },
      {
        time: "2018-10-22",
        open: 180.82,
        high: 181.4,
        low: 177.56,
        close: 178.75,
      },
      {
        time: "2018-10-23",
        open: 175.77,
        high: 179.49,
        low: 175.44,
        close: 178.53,
      },
      {
        time: "2018-10-24",
        open: 178.58,
        high: 182.37,
        low: 176.31,
        close: 176.97,
      },
      {
        time: "2018-10-25",
        open: 177.52,
        high: 180.5,
        low: 176.83,
        close: 179.07,
      },
    ];

    candleSeries.setData(data);

    function handleResize() {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, []);

  const handleFromSelectChange = (event, option) => {
    setSelectedFromValue(option.value);
    console.log("Selected From:", option);
  };

  const handleToSelectChange = (event, option) => {
    setSelectedToValue(option.value);
    console.log("Selected To:", option);
  };

  const handleChangeCommitted = (event, newValue) => {
    console.log("Final percentage:", newValue);
  };

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const options = [
    {
      label: "$WINNING",
      value: "1",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsfFjvNqWJn2M_iqFznB1oC-SK43WoaB6wyQ&s",
    },
    {
      label: "$SOL",
      value: "2",
      logo: "https://logos-world.net/wp-content/uploads/2024/01/Solana-Logo.png",
    },
    {
      label: "$USDC",
      value: "3",
      logo: "https://logos-world.net/wp-content/uploads/2021/02/USDC-Stablecoin-Logo.png",
    },
  ];

  const conditionOptions = [
    { value: "holders", label: "Holders" },
    { value: "price", label: "Price" },
    { value: "volume", label: "Volume" },
    { value: "market_cap", label: "Market Cap" },
    { value: "liquidity", label: "Liquidity" },
    { value: "volume", label: "Volume" },
  ];

  const comparisonOptions = [
    { value: "more_than", label: "More than" },
    { value: "equal_to", label: "Equal to" },
    { value: "below", label: "Below" },
  ];

  const actionOptions = [
    { value: "buy", label: "Buy" },
    { value: "sell", label: "Sell" },
  ];

  const expiryOptions = [
    { value: "1h", label: "1 hour" },
    { value: "4h", label: "4 hours" },
    { value: "12h", label: "12 hours" },
    { value: "24h", label: "24 hours" },
    { value: "48h", label: "48 hours" },
    { value: "7d", label: "7 days" },
    { value: "14d", label: "14 days" },
    { value: "30d", label: "30 days" },
    { value: "90d", label: "90 days" },
  ];

  const formatDisplay = (option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {option.logo && (
        <img
          src={option.logo}
          alt={option.label}
          style={{ width: 20, height: 20, marginRight: 8 }}
        />
      )}
      {option.label}
    </div>
  );

  const handleTabChange = (e, value) => {
    console.log({ e, value });
    setActiveTab(value);
  };

  return (
    <div className="dex-scroll-container">
      <CustomConnectButton />
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab value={0}>Market</Tab>
        <Tab value={1}>Limit</Tab>
        <Tab value={2}>Automations</Tab>
        <Tab value={3}>Settings</Tab>
        <Tab value={4}>Transactions</Tab>
      </Tabs>
      <TabBody>
        {activeTab === 0 && (
          <>
            {/* Market tab content */}
            <Fieldset label="Trade">
              FROM:
              <div className="flex row">
                <Select
                  options={options}
                  value={selectedFromValue}
                  onChange={handleFromSelectChange}
                  aria-label="From Token Select"
                  defaultValue="1"
                  className="custom-select"
                  disabled={false}
                  formatDisplay={formatDisplay}
                  menuMaxHeight="200px"
                  name="from-select"
                  style={{ width: "200px" }}
                  variant="outlined"
                />

                <NumberField
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  style={{ width: "200px" }}
                />
              </div>
              <div>Token Balance: 0</div>
              <div>
                <Divider />

                <Slider
                  value={sliderValue}
                  min={0}
                  max={100}
                  step={25}
                  onChange={handleChange}
                  onChangeCommitted={handleChangeCommitted}
                  orientation="horizontal"
                  variant="default"
                  marks={[
                    { value: 0, label: "" },
                    { value: 25, label: "25%" },
                    { value: 50, label: "50%" },
                    { value: 75, label: "75%" },
                    { value: 100, label: "" },
                  ]}
                />
              </div>
              TO:
              <div className="flex row">
                <Select
                  options={options}
                  value={selectedToValue}
                  onChange={handleToSelectChange}
                  aria-label="To Token Select"
                  defaultValue="2"
                  className="custom-select"
                  disabled={false}
                  formatDisplay={formatDisplay}
                  menuMaxHeight="200px"
                  name="to-select"
                  style={{ width: "200px" }}
                  variant="outlined"
                />

                <NumberField
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  style={{ width: "200px" }}
                />
              </div>
              <div>Balance: 0</div>
            </Fieldset>

            <Fieldset label="Price Chart">
              <div ref={chartContainerRef} className="trading-chart" />
            </Fieldset>

            <Divider style={{ margin: "16px 0" }} />

            <div className="action-buttons">
              <Button disabled onClick={() => console.log("Buy clicked")}>Buy</Button>
              <Button disabled onClick={() => console.log("Sell clicked")}>Sell</Button>
            </div>
          </>
        )}
        {activeTab === 1 && (
          <>
            {/* Limit tab content */}
            <p>Limit orders coming soon!</p>
          </>
        )}
        {activeTab === 2 && (
          <>
            {/* Automations tab content */}
            <Fieldset label="Automations">
              <div className="automation-container">
                <div className="automation-row">
                  <div className="automation-group">
                    <span>IF:</span>
                    <Select
                      options={conditionOptions}
                      value={automationCondition}
                      onChange={(e, option) =>
                        setAutomationCondition(option.value)
                      }
                      width={150}
                    />
                  </div>
                  <div className="automation-group">
                    <span>IS:</span>
                    <Select
                      options={comparisonOptions}
                      value={automationComparison}
                      onChange={(e, option) =>
                        setAutomationComparison(option.value)
                      }
                      width={150}
                    />
                    <NumberField
                      value={automationValue}
                      onChange={(e) => setAutomationValue(e.target.value)}
                      width={100}
                    />
                  </div>
                  <div className="automation-group">
                    <span>THEN:</span>
                    <Select
                      options={actionOptions}
                      value={automationAction}
                      onChange={(e, option) =>
                        setAutomationAction(option.value)
                      }
                      width={100}
                    />
                  </div>
                  <div className="automation-group">
                    <span>EXPIRES:</span>
                    <Select
                      options={expiryOptions}
                      value={automationExpiry}
                      onChange={(e, option) =>
                        setAutomationExpiry(option.value)
                      }
                      width={150}
                    />
                  </div>
                </div>
                <Button>Add Automation</Button>
              </div>
            </Fieldset>
          </>
        )}
        {activeTab === 3 && (
          <>
            {/* Settings tab content */}
            <Fieldset label="Trading Settings">
              <div className="settings-row">
                <span>Slippage Tolerance:</span>
                <NumberField
                  value={slippage}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value) && value >= 0) {
                      setSlippage(value);
                    }
                  }}
                  width={100}
                  min={0}
                  step={0.1}
                />
                <span>%</span>
              </div>
            </Fieldset>
          </>
        )}
        {activeTab === 4 && (
          <>
            {/* Transactions tab content */}
            <p>No previous transactions!</p>
          </>
        )}
      </TabBody>
    </div>
  );
};

function CustomConnectButton() {
  const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
  const [walletModalConfig, setWalletModalConfig] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const { buttonState, onConnect, onDisconnect, onSelectWallet } =
    useWalletMultiButton({
      onSelectWallet: setWalletModalConfig,
      // solflare is the only wallet adapter we have for now
      wallets: [
        {
          name: "Solflare",
          adapter: new SolflareWalletAdapter(),
        },
      ],
    });

  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction, wallet } = useWallet();

  const fetchWallet = useCallback(async () => {
    try {
      // TODO: CHANGE URL TO PROCESS ENV
      const res = await fetch(`${backendEndpoint}auth/connect-wallet`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          walletAddress: publicKey.toBase58(),
        }),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      if (publicKey) {
        localStorage.setItem("user_id", publicKey.toBase58());
        console.log("localStorage", localStorage.getItem("user_id"));
      }

      return res.json();
    } catch (error) {
      console.error("Error fetching wallet:", error);
      return null; // Or handle the error as appropriate for your app
    }
  }, [publicKey]); // Assuming publicKey could change and should trigger a re-memoization
  // Add any dependencies that on change should re-create this method

  // Handle label based on button state
  let label;
  switch (buttonState) {
    case "connected":
      label = "Disconnect";
      break;
    case "connecting":
      label = "Connecting";
      break;
    case "disconnecting":
      label = "Disconnecting";
      break;
    case "has-wallet":
      console.log("u hav wallet");
      label = "Connect Wallet";
      break;
    case "no-wallet":
      label = "Select Wallet";
      break;
    default:
      label = "Unknown";
  }

  const discardUserCookies = async () => {
    // Remove user cookies via endpoint disconnect-wallet
    try {
      // TODO: CHANGE URL TO PROCESS ENV
      const res = await fetch(`${backendEndpoint}auth/disconnect-wallet`, {
        method: "POST",
        credentials: "include",
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      // remove user_id from local storage
      localStorage.removeItem("user_id");

      return res.json();
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
      return null; // Or handle the error as appropriate for your app
    }
  };

  // Handle click events
  const handleClick = useCallback(() => {
    switch (buttonState) {
      case "connected":
        onDisconnect();
        // use iife to handle async operation
        (async () => {
          try {
            await discardUserCookies();
          } catch (error) {
            console.error("Error disconnecting wallet:", error);
          }
        })();

        break;
      case "connecting":
      case "disconnecting":
        // No action required
        break;
      case "has-wallet":
        //console.log("has-wallet");

        // Using an Immediately Invoked Function Expression (IIFE) for async operation
        (async () => {
          try {
            await onConnect();
            //console.log("onConnect");
            // setWalletConnected(true); // If you have a state to manage connection, set it here
            // Any additional logic following a successful connection can go here
          } catch (error) {
            console.error("Error connecting wallet:", error);
            // Handle any errors that occur during connection
          }
        })();
        break;
      case "no-wallet":
        onSelectWallet();
        break;
      default:
        // Handle any other states or default case
        break;
    }
  }, [buttonState, onDisconnect, onConnect, onSelectWallet]);

  // Effect to perform the API call after successfully connecting the wallet

  useEffect(() => {
    if (buttonState === "connected") {
      // Perform the API call
      const fetchAndHandleWallet = async () => {
        const res = await fetchWallet();
        // Handle the response from fetchWallet
        // Reset the flag after handling
        setWalletConnected(false);
      };
      fetchAndHandleWallet();
    }
  }, [walletConnected, label]); // This effect depends on the walletConnected state

  return (
    <div className="custom-connect-button-container">
      <Button
        disabled={
          buttonState === "connecting" || buttonState === "disconnecting"
        }
        onClick={handleClick}
        className="custom-connect-button"
        form="repoSearchForm"
      >
        {label}
      </Button>
      {console.log({ walletModalConfig })}
      {walletModalConfig ? (
        <List className="wallet-list">
          {walletModalConfig.wallets
            .filter((wallet) => wallet.adapter.name === "Solflare")
            .map((wallet) => (
              <ListItem
                size="sm"
                primary
                key={wallet.adapter.name}
                onClick={() => {
                  walletModalConfig.onSelectWallet(wallet.adapter.name);
                  setWalletModalConfig(null);
                }}
              >
                {wallet.adapter.name}
              </ListItem>
            ))}
        </List>
      ) : null}
    </div>
  );
}

export default DEX;
